.Thumbnail {
  color: #f1faee;

  border: 2px solid #457b9d;
}

.ThumbnailName {
  background-color: #457b9d;
}
.ThumbnailName p {
  color: #f1faee;
}
.Thumbnail img.smol {
  width: 9rem;
  height: 9rem;
  object-fit: cover;
}
