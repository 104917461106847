.Form {
  background-color: #f1faee;
  color: #1d3557;
}
.Form .btn {
  color: #f1faee;
  background-color: #457b9d;
}
.Form .badge {
  background-color: #1d3557;
  color: #f1faee;
}
.Form .btn-close {
  height: 0.6rem;
  width: 0.6rem;
}
