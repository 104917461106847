p {
  color: #1d3557;
}
.Exercise {
  background-color: #a8dadc;
  min-height: 100vh;
  font-size: 1.2rem;
}
.exerciseBox {
  background-color: #f1faee;
  min-height: 80vh;
}
.material-symbols-outlined {
  font-size: 3rem;
  vertical-align: middle;
  color: #1d3557;
}
/* .Exercise .carousel img {
  width: 30rem;
  height: 30rem;
  object-fit: cover;
} */
