.Feature {
  border: solid #457b9d;
  border-radius: 0.5rem;
  color: #1d3557;
  background-color: #f1faee;
  /* background-image: radial-gradient(#1d3557, #f1faee); */
  /* background-image: linear-gradient(to right, #1d3557 0%, #457b9d 15%, #457b9d 85%, #1d3557 100%); */
  /* background-image: linear-gradient(to right, #457b9d 0%, #1d3557 15%, #1d3557 85%, #457b9d 100%); */
  /* background-image: linear-gradient(to right, #f1faee, #f1faee 15%, #457b9d 15%, #457b9d 15%, #f1faee 85%, #f1faee 85%); */
  /* background-color: #1d3557; */
  /* border: 2px solid #457b9d; */
}
.Feature h1 {
  /* color: #1d3557; */
  color: #f1faee;
}
.FeatureHeading {
  background-color: #457b9d;
}
.Feature p {
  font-size: 1.5rem;
  /* color: #f1faee; */
}
