html {
  scroll-behavior: smooth;
  background-color: #1d3557;
}

/*
Colors:
light #f1faee
blue #457b9d
dark blue #1d3557
red #e63946
*/

body {
  /* background: rgb(236, 236, 236) */
  background: #f1faee;
  /* background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(103,241,255,1) 0%, rgba(232,232,232,1) 100%); */
  overflow-x: hidden;
}

.dropdown-menu {
  background-color: #f1faee;
}

/* #navDiv {
    background: #1d3557;
} */

section {
  overflow: hidden;
}

.modal-content {
  background-color: #f1faee;
}

/* p,
h1,
h2,
h3,
h4,
h5,
li,
hr,
label,
.lead {
    color: #1d3557;
} */

div.bg-alert-blue p,
div.bg-alert-blue h1,
div.bg-alert-blue h2,
div.bg-alert-blue h3,
div.bg-alert-blue h4,
div.bg-alert-blue h5,
div.bg-alert-blue li,
div.bg-alert-blue hr,
div.bg-alert-blue label,
div.bg-alert-blue .lead {
  color: #f1faee;
}

.accordion-body {
  background-color: #1d3557 !important;
}

.caveatFont {
  font-family: Caveat;
  /* font-size: 4rem; */
}

.caveat-font-sm {
  font-family: Caveat;
  font-size: 2rem;
}

.border {
  border: 1px solid #f1faee;
}

.img-thumbnail {
  background-color: #f1faee;
  border: 1px solid #f1faee;
}

/* h2 {
  text-shadow: 1px 1px 0px #1d3557; 
} */

.tiny {
  font-size: 0.8em;
  color: #1d3557;
}

a .tiny:hover {
  font-size: 0.8em;
  color: #457b9d;
}

section div ul li {
  font-size: 1.2rem;
}

ul.features li {
  /* font-size: 4vmin; */
  font-size: calc(0.9em + 1vmin);
  padding-bottom: 0.8em;
}

#navbar-row {
  background-color: #1d3557;
}

.social-icon {
  width: 30px;
  height: 30px;
}

.social-icon:hover {
  transform: scale(1.2);
}

.alert-white {
  color: #f3f8f1 !important;
}

.alert-red {
  color: #e63946;
  font-weight: bold;
}

.alert-blue {
  color: #1d3557 !important;
}

.alert-lighterblue {
  color: #457b9d;
}

.bg-alert-blue {
  background-color: #1d3557;
}

.bg-alert-blue {
  background-color: #1d3557;
}

.bg-alert-lighterblue {
  background-color: #457b9d;
}

.border-alert-lightblue {
  border: solid #a8dadc 3px !important;
}

/* .blurb-div {
    background-color: #f1faee;
    border: 5px solid #457b9d;
    border-radius: 10px;
}
.blurb-div h2 {
    text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
    color: #457b9d;
} */

.oops-blurb h2 {
  color: #e63946;
  font-family: "Monda", sans-serif;
  /* text-shadow: 1px 1px 0px rgba(0,0,0,0.2); */
}

.yay-blurb h2 {
  color: #457b9d;
  font-family: "Monda", sans-serif;
  /* text-shadow: 1px 1px 0px rgba(0,0,0,0.2); */
}

#oops {
  width: 300px;
  height: 250px;
}

/* .blurb {
    font-family: 'Monda', sans-serif;
    text-shadow: 1px 1px 0px rgba(0,0,0,0.2);
} */

.jumbo {
  background: #f3f8f1;
  border: solid #457b9d 1px;
  border-radius: 10px;
  /* box-shadow: 0 .5rem 1rem #457b9d; */
}

.card {
  background: #f3f8f1;
  border: solid #457b9d 1px;
  min-height: 1vh;
  /* min-height: 500px; */
}

/* .carousel-item img {
    width: 100%;
    max-width: 50vmax;
} */
#centre {
  background-color: #f1faee;
  /* background-color:#1d3557; */
}

/* .register {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
} */

.RA-btn {
  background-color: #457b9d;
  color: #f1faee !important;
  border: none;
}

.RA-btn:hover {
  background-color: #24426d;
  color: #f1faee;
}

/* .accordion-button {
    background-color: #f1faee;
    color: #1d3557 !important;
    border: none;
}
.accordion-button:not(.collapsed) {
    background-color: #f1faee;
    color: #1d3557 !important;
    border: none;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #457b9d;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(69, 123, 157 / 25%);
}
.RA-btn.accordion-button:not(.collapsed) {
    background-color: #457b9d;
    color: #f1faee !important;
    border: none;
} */

.form-check-input:checked {
  background-color: #457b9d;
  border-color: #457b9d;
}

.material-symbols-outlined {
  color: #457b9d;
  /* color: #24426d; */
}

.tippy-box {
  color: #f1faee;
  background-color: #457b9d;
}

.tippy-arrow {
  color: #457b9d;
}

.features-table {
  color: #1d3557;
}

.features-table th {
  font-size: 0.8rem;
}

.RA-feature-list li {
  font-size: 1.2rem;
  line-height: 2rem;
}

/* .features-table th {
    color: #457b9d;
} */

/* .RA-highlight { */
/* color: #1d3557!important; */
/* background-color: #a8dadc; */
/* text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2); */
/* border-left: 1px solid #f1faee;
    border-right: 1px solid #f1faee;
    border-top: 1px solid #f1faee; */
/* padding-top: 5px; */
/* border-right: 1px solid #f1faee; */
/* transition: all 100ms linear; */
/* } */
/* .RA-whorow {
    visibility: visible;
    opacity: 1;
    animation: fade-in 1s;
    display: flex;
}
.RA-whorow-hide {
    display: none;
    visibility: hidden;
    opacity: 0;
    animation: fade-in 1s;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
} */

#alertRed {
  color: #e63946;
}

#mainImg {
  size: 500px !important;
}

/* #formJumbo {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
} */

#backToTop {
  font-size: 48px;
  /* color: #457b9d; */
  color: #a8dadc;

  text-shadow: 1px 1px 2px #457b9d;
  position: fixed;
  bottom: 26px;
  right: 26px;
  z-index: 1;
}

#backToTop:hover {
  font-size: 50px;
  text-shadow: 3px 3px 3px #1d3557;
}

#currencyBtn:focus {
  box-shadow: none !important;
}

/* #register {
    background-color: rgb(227, 247, 248);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
} */

/* SendinBlue styling */
/* @font-face {
    font-display: block;
    font-family: Roboto;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff")
}

@font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 600;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff")
}

@font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 700;
    src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"), url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff")
} */

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
